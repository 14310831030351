import { useCallback, useEffect, useState, useMemo } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import { prop } from 'ramda';
import { ONE_YEAR_SECONDS, compact } from '@moda/portal-stanchions';
import { reportError } from '../../lib/reportError';
import { getVariantClickHistory } from '../../lib/variantClicksHistory';
import { tc } from '../../lib/trackingContext';
import { useCountry } from '../../components/CountrySelector';
import { useCartVersion } from '../useCart';
import { GET_CART_QUERY } from '../useCart/cart.rest';
import { useCookies } from '../useCookies';
import { AddToBagFragment } from '../../generated/types';
import { ADD_SET_TO_BAG_MUTATION } from './addSetToBag.rest';

export enum AddSetToBagMode {
  Resting = 'Add Set To Bag',
  Pending = 'Adding Set to Bag',
  Added = 'Added to cart!',
  Error = 'Error Adding Set to Bag'
}

export type Item = {
  customization?: string | null;
  isCustomizationValid?: boolean | null;
  href?: string | null;
  position?: number;
  size?: string | null;
  onAdded?: () => void;
};

const SHOW_ADDED_MS = 1000;
const SHOW_ERROR_MS = 2000;

const getButtonText = (mode: AddSetToBagMode, items: Item[]) => {
  if (items.length === 0) return 'Sold Out';

  return mode;
};

export const useAddSetToBag = (variants: AddToBagFragment[], restingText: string) => {
  const [mode, setMode] = useState<AddSetToBagMode>(restingText as AddSetToBagMode);

  const { country } = useCountry();
  const {
    cookies: { cartId },
    setCookie
  } = useCookies();
  const client = useApolloClient();
  const cartVersion = useCartVersion();

  const availableVariants = useMemo(
    () => variants.filter(variant => variant.masterVariant.inventory.some(size => size?.canSell)),
    [variants]
  );

  const queryID =
    compact(availableVariants.map(variant => getVariantClickHistory(variant.id)))[0]?.queryId ??
    availableVariants[0]?.queryid;

  const items: Item[] = useMemo(
    () =>
      availableVariants.map(variantItem => ({
        availability: variantItem.availability,
        cart_id: cartId,
        country_code: country.alpha2Code,
        product_id: variantItem.productId,
        variant_id: variantItem.id,
        position: 1,
        size: variantItem.masterVariant.inventory.find(size => size?.canSell)?.size || 'OS'
      })),
    [availableVariants, cartId, country.alpha2Code]
  );

  const [addSetToBag, { loading }] = useMutation(ADD_SET_TO_BAG_MUTATION, {
    onCompleted: data => {
      setMode(AddSetToBagMode.Added);
      data?.addSetToBag?.data?.map(cart => {
        tc.track('Product Added', {
          addToScopeOnce: {
            products: cart?.attributes?.cartItems.map(prop('data'))
          },
          addToTrackingEvent: {
            label: cart?.id as string,
            eventType: 'conversion',
            index: availableVariants[0].index,
            queryID,
            objectIDs: availableVariants.map(variant => variant.objectid)
          }
        });
      });
    },
    onError: error => {
      reportError('ADD_SET_TO_BAG', error);
      setMode(AddSetToBagMode.Error);
    },
    update: (cache, { data }) => {
      if (!data) return;

      const newCartId = data.addSetToBag.data[0].id;

      if (!newCartId) return;

      if (!cartId) {
        setCookie('cartId', newCartId, ONE_YEAR_SECONDS);
      }

      cache.writeQuery({
        query: GET_CART_QUERY,
        variables: { cartId: newCartId, cartVersion, countryCode: country.alpha2Code },
        data: { userCart: { ...data.addSetToBag, __typename: 'RestUserCart' } }
      });
      tc.setCurrent('cart', data.addSetToBag.data[0]);
    }
  });

  const handleAddSetToBag = useCallback(async () => {
    setMode(AddSetToBagMode.Pending);

    if (cartId) {
      // make sure the cart is fetched before running the mutation
      await client.query({
        query: GET_CART_QUERY,
        variables: { cartId, cartVersion, countryCode: country.alpha2Code }
      });
    }

    addSetToBag({
      variables: {
        input: {
          data: {
            attributes: {
              items
            },
            type: 'line_items'
          }
        }
      }
    });
  }, [addSetToBag, cartId, cartVersion, client, country.alpha2Code, items]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (mode === AddSetToBagMode.Added) {
      timeout = setTimeout(() => setMode(restingText as AddSetToBagMode), SHOW_ADDED_MS);
    }

    return () => clearTimeout(timeout);
  }, [mode, restingText]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (mode === AddSetToBagMode.Error) {
      timeout = setTimeout(() => setMode(restingText as AddSetToBagMode), SHOW_ERROR_MS);
    }

    return () => clearTimeout(timeout);
  }, [mode, restingText]);

  const text = getButtonText(mode, items);

  return {
    added: mode === AddSetToBagMode.Added,
    disabled: mode !== restingText || items.length === 0,
    error: mode === AddSetToBagMode.Error,
    handleAddSetToBag,
    loading,
    text
  };
};
